import React, { Component } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import BG from "../img/user.png";
import LG from "../img/logo.png";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      Username: '',
      Password: '',
      Code: '',
      RePassword: '',
    };

    this.resetPassword = this.resetPassword.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  handleFormSubmission(evt) {
    if (this.state.Password === this.state.RePassword && this.state.Password !== '') {
      evt.preventDefault();
      if (this.validatePassword(this.state.Password)) {
        this.resetPassword();
      } else {
        alert("La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 carácter especial y 1 número.");
      }
    } else {
      alert("Las contraseñas no son iguales");
    }
  }

  // Validar la contraseña con una expresión regular
  validatePassword(password) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }

  mostrarContrasena() {
    var tipo = document.getElementById("password-field");
    if (tipo.type === "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }
  
  mostrarContrasena2() {
    var tipo = document.getElementById("password-field2");
    if (tipo.type === "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }

  async resetPassword() {
    const username = this.props.SignUpUsername;
    const code = this.state.Code;
    const password = this.state.Password;

    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      this.props.SetAuthState(AuthState.SignIn);
    } catch (error) {
      console.log(error);
      if (error === "CodeMismatchException: Invalid verification code provided, please try again.") {
        alert("Se proporcionó un código de verificación no válido, inténtelo de nuevo.");
      }

      if (error === "LimitExceededException: Attempt limit exceeded, please try after some time.") {
        alert("Se excedió el límite de intentos, inténtelo después de un tiempo.");
      }
    }
  }

  render() {
    return (
      <div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration">
          </div>
          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2">Nueva contraseña</h2>
              <div className="form-group">
                <input
                  className="text-center form-control"
                  type="text"
                  placeholder={this.props.SignUpUsername}
                  onChange={(e) => this.setState({ Username: e.target.value })}
                  disabled
                ></input>
              </div>
              <div className="form-group">
                <input
                  className="text-center form-control"
                  type="number"
                  placeholder="Código de verificación"
                  onChange={(e) => this.setState({ Code: e.target.value })}
                ></input>
              </div>
              <div className="form-group">
                <input
                  id="password-field"
                  className="text-center form-control"
                  type="password"
                  placeholder="Nueva Contraseña"
                  onChange={(e) => this.setState({ Password: e.target.value })}
                ></input>
                <span
                  className="material-symbols-outlined  field-icon"
                  onClick={this.mostrarContrasena}
                >
                  visibility
                </span>
              </div>
              <div className="form-group">
                <input
                  id="password-field2"
                  className="text-center form-control"
                  type="password"
                  placeholder="Confirmar contraseña"
                  onChange={(e) => this.setState({ RePassword: e.target.value })}
                ></input>
                <span
                  className="material-symbols-outlined  field-icon"
                  onClick={this.mostrarContrasena2}
                >
                  visibility
                </span>
              </div>
              <div className="form-group">
                <a
                  className="form-control btn btn-primary"
                  onClick={this.handleFormSubmission}
                >
                  Cambiar contraseña
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
