import { SignIn } from "aws-amplify-react/lib/Auth";
import React from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import BG from "../img/user.png";
import LG from "../img/logo.png";
import Box from "@mui/material/Box";
import LoadingScreen from "react-loading-screen";
import Spinner from "./S.gif";
import ReactPlayer from "react-player/lazy";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      Username: "",
      Password: "",
      IsLoading:false,
    };

    this.signIn = this.signIn.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  handleFormSubmission(evt) {
    evt.preventDefault();
    this.signIn();
    this.setState({ IsLoading: true });
  }

  mostrarContrasena() {
    var tipo = document.getElementById("password-field");
    if (tipo.type == "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }

  async signIn() {
    const username = this.state.Username;
    const password = this.state.Password;
    try {
      await Auth.signIn(username, password);
      await this.props.SetAuthState(AuthState.SignedIn);
    } catch (err) {
      this.setState({ IsLoading: false });
    
      if (err.code === "UserNotConfirmedException") {
        this.setState({ error: "Login failed." });
        console.log("1");
      } else if (err.code === "NotAuthorizedException") {
        this.setState({ error: "Login failed." });
        console.log("2");
        this.setState({ IsLoading: false });
        alert("El usuario o la contraseña no coinciden")

      } else if (err.code === "UserNotFoundException") {
        this.setState({ error: "Login failed." });
        console.log("3");

      } else {
        this.setState({ error: "An error has occurred." });
        console.error(err);

      }
    }
  }

  showComponent(theme) {
    return (
      <div className="colorfondo">
        {this.state.IsLoading ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={"Ingresando..."}
          />
        ) : (
          <div class="parent clearfix">
            <div class="bg-illustration"></div>

            <div class="login">
              <div class="container">
                <br></br>
                <h2 className="titleOD2"> Iniciar Sesión</h2>

                <img className="avatar2" src={BG} alt="logo" />
                <br></br>
                <br></br>
                <div className="form-group">
                  <input
                    className="form-control text-center"
                    type="text"
                    placeholder="Nombre de usuario"
                    onChange={(e) =>
                      this.setState({ Username: e.target.value })
                    }
                  ></input>
                </div>

                <div className="form-group">
                  <input
                    id="password-field"
                    className="text-center form-control"
                    type="password"
                    placeholder="Contraseña"
                    onChange={(e) =>
                      this.setState({ Password: e.target.value })
                    }
                  ></input>
                  <span
                    className="material-symbols-outlined  field-icon "
                    onClick={this.mostrarContrasena}
                  >
                    visibility
                  </span>
                </div>

                <div className="form-group">
                  <a
                    className="form-control btn btn-primary"
                    onClick={this.handleFormSubmission}
                    href="#0"
                  >
                    Iniciar Sesión
                  </a>
                </div>
                <div className="FlexContainer">
                  <div className="">
                    <a
                      className=""
                      onClick={() =>
                        this.props.SetAuthState(AuthState.ForgotPassword)
                      }
                      href="#0"
                    >
                      Olvidé mi contraseña
                    </a>
                  </div>
                  <div className="">
                    <a
                      className=""
                      onClick={() => super.changeState("signUp")}
                      href="#0"
                    >
                      Registrarse
                    </a>
                  </div>
                </div>

                <div className="FlexContainer">
                  <div className="">
                    <a
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <ReactPlayer
                            className="react-player modalVideoReactplay"
                            url="https://hisoporte.s3.amazonaws.com/videos/od2/Casa_IAIA/cambiar_Contrase%C3%B1a.mp4"
                            width="100%"
                            height="100%"
                            controls={true}
                          />,
                          {
                            className: "modalTItle",
                            title: "",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                      href="#0"
                    >
                      ¿Cómo recupero mi contraseña?
                      <i className="far fa-play-circle" />
                    </a>
                  </div>

                  <div className="">
                    <a
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <ReactPlayer
                            className="react-player modalVideoReactplay"
                            url="https://hisoporte.s3.amazonaws.com/videos/od2/Casa_IAIA/registar_Proveedor.mp4"
                            width="100%"
                            height="100%"
                            controls={true}
                          />,
                          {
                            className: "modalTItle",
                            title: "",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                      href="#0"
                    >
                      ¿Cómo me registro ?
                      <i className="far fa-play-circle" />
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CustomSignIn;
